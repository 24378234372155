export default (data = {}) => {
    return {
        account: null,
        code: null,
        store_id: null,
        user_email: null,
        user_password: null,
        user_name: null,
        user_access: null,
        token: null,
        token_active: null,
        publickey: null,
        ...data
    }
}

<template>
  <v-app id="inspire">
    <!-- <v-navigation-drawer
      class="navigation-drawer"
      :value="$store.getters.getMenuStatus"
      @input="(val) => (drawer = val)"
      v-click-outside="setdrawerClose"
      app
      v-if="!$vuetify.breakpoint.xsOnly"
    >
      <v-list-item>
        <v-avatar rounded>
          <v-img icon contain :src="weblogo" height="35"></v-img>
        </v-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.getters.getManifest.name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $store.state.profile.user_access
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>


      <v-treeview
        return-object
        item-key="id"
        activatable
        :open="open"
        open-on-click
        @update:active="updateForm"
        @update:open="updateOpen"
        :items="menu"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ item.icon }}
          </v-icon>
        </template>
      </v-treeview>

      <v-divider></v-divider>

      <template v-slot:append>
        <v-list-item to="About">
          <v-avatar rounded>
            <v-icon color="green" large>mdi-information-outline</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>Powered by NGM</v-list-item-title>
            <v-list-item-subtitle>New Global Market Corp.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer> -->

    <v-app-bar :value="$store.getters.getNavStatus" app>
      <v-app-bar-nav-icon to="Contact">
        <v-img contain :src="weblogo" height="40"></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title> {{ this.company.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <button @click="install">install</button> -->
      <v-menu transition="slide-x-transition" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <img
              :src="$store.getters.profile.Picture"
              v-if="$store.getters.profile.Picture"
            />
            <v-icon v-else>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <template>
          <v-list v-if="this.$store.state.profile.code">
            <v-list-item to="Profile">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.$store.state.profile.name }}
                </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item to="MyOrders">
              <v-list-item-avatar>
                <v-icon>mdi-file-document-multiple-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Mis Ordenes </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item to="Registration">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Registrarse </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>

    <v-main>
      <ai_agent />
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-scale-transition>
      <v-btn
        fab
        dark
        v-show="fab"
        v-scroll="onScroll"
        fixed
        bottom
        right
        color="#7494a4"
        @click="toTop"
        class="fabbtn"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      centered
      vertical
      outlined
      :color="snackToast.color"
      elevation="24"
    >
      {{ this.snackToast.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackToast.dialog = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog :value="loadingstatus" hide-overlay persistent max-width="480">
      <v-card color="primary" dark>
        <v-card-text>
          Trabajando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <WelcomeSplash :open="splashDialog" @close="splashDialog = !splashDialog" />
  </v-app>
</template>

<script>
import update from "./mixins/update";
import ai_agent from "./components/ai/ai_agent.vue";

import createItem from "./utils/createUser.js";
import createParty from "./utils/createParty.js";
import WelcomeSplash from "./components/splash.vue";

// const TIME_IN_BOUNDARY = 8;
// const TIME_OUT_BOUNDARY = 24;
// const INTERVAL = 900 * 1000;

export default {
  beforeMount() {
    window.stores = [];
    this.$store.dispatch("setHost", window.location.host);
    //this.$store.dispatch("setHost", "cqf.vialmar.co");
    //this.$store.dispatch("setHost", "montemar.vialmar.co");
    // this.$store.dispatch("setHost", "qunno.sw67.ca");
    //this.$store.dispatch("setHost", "liquorstore.sw67.ca");
    //this.$store.dispatch("setHost", "marquesa.sw67.ca");
    //this.$store.dispatch("setHost", "launiversal.sw67.ca");
    //this.$store.dispatch("setHost", "todofer.co");
    //this.$store.dispatch("setHost", "nevada.vialmar.co");
    // this.$store.dispatch("setHost", "vialmar.co");

    var profile = window.localStorage.getItem("profile");
    if (profile) {
      var client = JSON.parse(
        this.CryptoJS.AES.decrypt(profile, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      //  console.log("profile", client);
      this.$store.dispatch("setProfile", client);
    }
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = root + "/logo.webp";
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
    this.onResize();
    //this.menu = this.$store.getters.getMenuItems;
  },
  data: () => ({
    deferredPrompt: null,
    weblogo: null,
    manifest: {},
    splashDialog: true,
    updateExists: false,
    loadingstatus: false,
    company: createParty(),
    profile: { name: null },
    item: createItem(),
    client: createParty(),
    navStatus: true,
    open: [],
    menu: [
      {
        id: 0,
        name: "Catálogo",
        icon: "mdi-storefront-outline",
        to: "/",
        color: "#064c72",
      },
      {
        id: 2,
        name: "Mis Ordenes",
        icon: "mdi-file-document-multiple-outline",
        to: "MyOrders",
        color: "#064c72",
      },
    ],
    tracker: null,
    fab: null,
    mini: true,
    valid: false,
    reg_dialog: false,
    f_email_val: [
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
    ],
    f_required: [(v) => !!v || "Requerido"],
    snackToast: {
      timeout_error: 2000,
      dialog: false,
      text: "",
      color: "success",
    },
    logo: "",
  }),
  mixins: [update],
  components: { WelcomeSplash, ai_agent },
  computed: {
    showDrawer() {
      return this.$store.getters.getMenuStatus && this.drawer;
    },
    showLoading() {
      return this.$store.getters.getLoading;
    },
  },
  watch: {
    "$store.getters.getLoading"(newVal) {
      if (newVal) {
        this.loadingstatus = this.$store.getters.getLoading;
      }
    },
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = true;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.$store.dispatch("set_menuItems", this.menu);
        this.client = this.$store.getters.profile;
      }
    },
    "$store.getters.getMenuItems"(newVal) {
      if (newVal) {
        this.menu = newVal;
        //this.track();
      }
    },
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.tracker);
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
    },
    updateForm(item) {
      if (item[0].to)
        this.$router.push({
          name: item[0].to,
          params: item[0].params,
          query: item[0].query,
        });
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    setdrawer() {
      this.$store.dispatch("set_menu", !this.$store.getters.getMenuStatus);
    },
    setdrawerClose() {
      if (this.$vuetify.breakpoint.xsOnly && !this.$store.getters.getMenuStatus) {
        this.$store.dispatch("set_menu", !this.$store.getters.getMenuStatus);
      }
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },

    logout() {
      localStorage.removeItem("profile");
      localStorage.removeItem("company");
      this.$store.dispatch("setProfile", createParty());
      this.$store.dispatch("setCompany", createParty());
      this.$router.push("/");
    },
  },
};
</script>

<style lang="css">
.fabbtn {
  z-index: 1000;
  bottom: 100px !important;
}
.top_m {
  top: 64x;
}
.totals {
  font-weight: bold !important;
  font-size: large !important;
}
.floating-btn {
  top: 80px !important;
  z-index: 1000;
}
</style>

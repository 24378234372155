<template>
  <v-container :height="best_height" class="pa-0">
    <v-app-bar fixed elevation="0" dark v-if="!$vuetify.breakpoint.xsOnly" app>
      <v-app-bar-nav-icon to="Contact">
        <v-img contain :src="weblogo" height="40"></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ this.$store.getters.company.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        light
        placeholder="Buscar"
        class="pt-2"
        full-width
        v-model="tosearch"
        append-icon="mdi-magnify"
        clearable
        outlined
        hide-details
        filled
        dense
        background-color="white"
        @keyup="findProduct"
      >
        <template v-slot:prepend-inner>
          <v-menu transition="slide-x-transition" bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>
            </template>
            <template>
              <v-list>
                <v-list-item
                  v-for="(child, index) in smart_categories"
                  :key="index"
                  @click="changeData(child)"
                >
                  <v-list-item-title>{{ child }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
          <!-- <v-select
            hide-details
            item-text="text"
            item-value="value"
            :items="smart_categories"
            @change="changeData($event)"
            class="text-uppercase text-truncate select-preend"
            dense
          /> -->
        </template>
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon color="white" dark to="Cart" v-if="sc.qty > 0">
        <v-badge bordered overlap :content="sc.qty">
          <v-icon :disabled="!sc.qty > 0" color="#ff9900"> mdi-cart-outline </v-icon>
        </v-badge>
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-bottom-navigation dark app v-if="$vuetify.breakpoint.xsOnly">
      <v-btn @click="catetory = ''">
        <v-icon large> mdi-home-circle-outline</v-icon>
      </v-btn>

      <v-text-field
        light
        placeholder="Buscar"
        rounded
        class="pt-2 mr-2"
        full-width
        v-model="tosearch"
        append-icon="mdi-magnify"
        clearable
        outlined
        hide-details
        filled
        background-color="white"
        dense
        @keyup="findProduct"
      />
      <v-btn fab elevation="0" to="Cart" v-if="sc.qty > 0">
        <v-badge bordered overlap :content="sc.qty">
          <v-icon large :disabled="!sc.qty > 0"> mdi-cart-outline </v-icon>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>

    <template v-if="catetory === ''">
      <v-carousel
        height="200px"
        interval="6000"
        continuous
        show-arrows-on-hover
        hide-delimiters
        cycle
      >
        <v-carousel-item
          v-for="(item, i) in main_lst"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
          color="grey lighten-2"
          class="text-center pa-2"
        >
          <v-sheet height="100%" tile>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md6>
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="6" class="text-rigth">
                    <v-img
                      contain
                      max-height="190"
                      :src="getImagePhoto(item.account, item.store, item.picture)"
                    ></v-img>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <h1>{{ item.product_name }}</h1>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-divider></v-divider>
      <v-container fluid fill-height>
        <v-layout align-center justify-center v-if="!$vuetify.breakpoint.xsOnly">
          <v-flex xs12 sm12 md12 lg10>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                lg="3"
                class="d-flex flex-column"
                v-for="(child, index) in main_lst"
                :key="index"
              >
                <v-card class="elevation-1 flex d-flex flex-column pa-2">
                  <v-img
                    height="200"
                    width="100%"
                    contain
                    :src="getImagePhoto(child.account, child.store, child.picture)"
                    v-if="child.picture"
                  ></v-img>
                  <v-btn
                    v-if="child.discount"
                    color="success"
                    elevation="10"
                    rounded
                    dark
                    small
                    absolute
                    top
                    right
                    >{{ "-" + child.percent + "%" }}</v-btn
                  >
                  <v-card-title class="text-wrap">
                    <b>{{ child.product_name }}</b>
                  </v-card-title>
                  <v-card-subtitle
                    class="three-lines"
                    v-if="child.description"
                    v-html="child.description"
                  >
                  </v-card-subtitle>

                  <v-card-actions v-if="child.pack > 0">
                    <span>{{
                      parseFloat(child.size).toLocaleString("es") + " " + child.uom
                    }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold text-lowercase"> </span>
                    <v-chip
                      @click="addItem(child)"
                      color="light-green lighten-4"
                      v-if="$store.getters.profile.code"
                    >
                      {{
                        "$" +
                        Math.round(
                          parseFloat(child.price) - parseFloat(child.discount)
                        ).toLocaleString("es")
                      }}
                      <v-icon right> mdi-cart-outline </v-icon>
                    </v-chip>
                    <v-chip @click="addItem(child)" color="light-green lighten-4"
                      >Ver mas ...</v-chip
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>

        <v-list width="100%" v-else>
          <v-list-item
            v-for="catego in smart_categories"
            :key="catego"
            @click="changeData(catego)"
            dense
          >
            <v-list-item-avatar rounded size="80px" class="categories">
              <v-img contain :src="getbgpic(catego)" />
            </v-list-item-avatar>
            <v-list-item-title>
              <h2>{{ catego }}</h2>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-container>
    </template>

    <template v-else>
      <v-container fluid fill-height>
        <v-list
          width="100%"
          class="pa-0 ma-0"
          three-line
          v-if="$vuetify.breakpoint.xsOnly"
        >
          <v-list-item
            v-for="(child, index) in smart_lst_selected"
            :key="index"
            @click="addItem(child)"
            class="pl-0 pr-0"
            max-width="374"
          >
            <v-btn
              v-if="child.discount"
              color="success"
              elevation="10"
              rounded
              dark
              small
              absolute
              top
              rigth
              class="topleft"
              >{{ "-" + child.discount + "%" }}</v-btn
            >
            <v-list-item-avatar rounded size="64">
              <v-img
                contain
                :src="getImagePhoto(child.account, child.store, child.picture)"
                v-if="child.picture"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <b> {{ child.product_name }}</b>
              </v-list-item-title>
              <v-list-item-subtitle v-html="child.description"> </v-list-item-subtitle>
              <v-list-item-actions class="pa-0" v-if="$store.getters.profile.code">
                <v-toolbar flat class="ma-0 pa-0">
                  <span>
                    {{ child.size }} {{ child.uom + "     " }}
                    <span v-if="child.pack > 1">{{ " x " + child.pack }}</span>
                  </span>
                  <v-spacer></v-spacer>
                  <v-chip
                    @click="addItem(child)"
                    color="light-green lighten-4 font-weight-bold text-lowercase"
                  >
                    {{
                      "$" +
                      Math.round(
                        parseFloat(child.price) - parseFloat(child.discount_value)
                      ).toLocaleString(0)
                    }}
                  </v-chip>
                </v-toolbar>
              </v-list-item-actions>
              <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-layout
          align-center
          justify-center
          class="toptop"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <v-flex xs12 sm10 md10>
            <v-row>
              <v-col
                cols="12"
                md="4"
                lg="3"
                class="pa-3 d-flex flex-column"
                v-for="(child, index) in smart_lst_selected"
                :key="index"
              >
                <!-- <v-list width="100%" class="pa-0 ma-0" three-line>
                  <v-list-item height="80px">
                    <v-btn
                      v-if="child.discount"
                      color="success"
                      elevation="10"
                      rounded
                      dark
                      small
                      absolute
                      top
                      rigth
                      class="topleft"
                      >{{ "-" + child.discount + "%" }}</v-btn
                    >
                    <v-list-item-avatar rounded class="categories" size="64">
                      <v-img
                        cover
                        :src="getImagePhoto(child.account, child.store, child.picture)"
                        v-if="child.picture"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">
                        {{ child.product_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-html="child.description">
                      </v-list-item-subtitle>
                      <v-toolbar tile elevation="0" dense>
                        {{ child.size }} {{ child.uom }}
                        <span v-if="child.pack > 1">{{ " X " + child.pack }}</span>
                        <v-spacer></v-spacer>
                        <span>{{
                          parseFloat(child.price_uom).toFixed(2) +
                          " x " +
                          child.normalized_uom
                        }}</span>
                        <v-spacer></v-spacer>
                        <span class="font-weight-bold text-lowercase"> </span>
                        <v-chip @click="addItem(child)">
                          {{
                            "$" +
                            Math.round(
                              parseFloat(child.price) - parseFloat(child.discount)
                            ).toLocaleString("es") +
                            " x " +
                            child.uom
                          }}
                          <v-icon right> mdi-cart-outline </v-icon>
                        </v-chip>
                      </v-toolbar>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
                <v-card class="elevation-5 flex d-flex flex-column pa-2">
                  <v-img
                    height="200"
                    width="100%"
                    contain
                    :src="getImagePhoto(child.account, child.store, child.picture)"
                    v-if="child.picture"
                  ></v-img>
                  <v-btn
                    v-if="child.discount"
                    color="success"
                    elevation="10"
                    rounded
                    dark
                    small
                    absolute
                    top
                    right
                    >{{ "-" + child.percent + "%" }}</v-btn
                  >
                  <v-card-title class="text-wrap">
                    <b>{{ child.product_name }}</b>
                  </v-card-title>
                  <v-card-subtitle
                    class="three-lines"
                    v-if="child.description"
                    v-html="child.description"
                  >
                  </v-card-subtitle>
                  <!-- <v-spacer></v-spacer>
                  <v-btn
                    v-if="child.discount"
                    color="red"
                    elevation="0"
                    text
                    dark
                    small
                    absolute
                    bottom
                    right
                    class="btnprice"
                  >
                    <span class="text-decoration-line-through">{{
                      "$" + parseFloat(child.price).toLocaleString()
                    }}</span>
                  </v-btn> -->

                  <v-card-actions v-if="child.pack > 0">
                    <span>{{
                      parseFloat(child.size).toLocaleString("es") + " " + child.uom
                    }}</span>
                    <v-spacer></v-spacer>

                    <v-chip
                      @click="addItem(child)"
                      color="light-green lighten-4 font-weight-bold text-lowercase"
                    >
                      {{
                        "$" +
                        Math.round(
                          parseFloat(child.price) - parseFloat(child.discount)
                        ).toLocaleString("es")
                      }}
                      <v-icon right> mdi-cart-outline </v-icon>
                    </v-chip>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <v-bottom-sheet
      persistent
      hide-overlay
      dark
      v-model="snackToast.dialog"
      :color="snackToast.color"
    >
      <v-sheet class="text-center" height="200px">
        <v-progress-circular :size="150" color="amber" indeterminate>
          <h3>{{ this.snackToast.text }}</h3>
        </v-progress-circular>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
//import CartAdd from "../../components/Cart_add.vue";
//import ShoppingCart from "../../components/Cart.vue";
import createItem from "../../utils/createItem.js";
import createParty from "../../utils/createParty.js";

export default {
  components: {},
  beforeMount() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = root + "/logo.webp";
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.get_products();
    if (this.$route.params.Cart) {
      this.cart_dialog = this.$route.params.Cart;
    }

    this.onResize();
    this.$store.dispatch("setNavStatus", true);
    this.updateTotals();
    if (this.$route.params.category) {
      this.category = this.$route.params.category;
      this.changeData(this.category);
    }
  },
  data: () => ({
    weblogo: null,
    main_lst: [],
    idx: null,
    company: createParty(),
    company_color: "#064c72",
    cart_dialog: false,
    isXs: false,
    categories: [],
    items: [
      {
        id: 3,
        name: "Mis pedidos",
        description: "Creear, editar o eliminar pedidos.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
      {
        id: 1,
        name: "Lista de Precios",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "warning",
        to: "Proveedores",
      },
      {
        id: 2,
        name: "Proveedores",
        description: "Creear, editar o eliminar proveedores.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },

      {
        id: 4,
        name: "Mi perfil",
        description: "Editar perfil, logo, información de contacto.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
    ],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    menu: [
      {
        id: 1,
        name: "Hacer pedido",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "#455A64",
        to: "/",
      },
      {
        id: 2,
        name: "Historico de Pedidos",
        description: "Creear, editar o eliminar clientes.",
        icon: "mdi-file-document-edit-outline",
        to: "MyOrders",
        color: "#455A64",
      },
    ],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    headers: {
      false: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Size",
          align: "center",
          value: "size",
          width: 2,
        },
        {
          text: "",
          align: "center",
          value: "price_uom",
          width: 2,
        },
        {
          text: "Pack.",
          align: "center",
          value: "pack",
          width: 2,
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      true: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
    },
    header_orders: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "issue_date",
        dataType: "date",
      },
      {
        text: "Orden",
        align: "start",
        value: "doc_code",
        dataType: "text",
      },
      {
        text: "Valor",
        align: "end",
        sortable: true,
        value: "total",
      },
    ],
    snackToast: {
      timeout_error: 500,
      dialog: false,
      text: "",
      color: "warning",
    },
    tosearch: "",
    catetory: "",
    reg_dialog: false,
    cart: [],
    apilink: "",
    best_height: "100%",
    best_widht: "500",
  }),
  created: function () {
    this.apilink = "http://";
    //this.apilink += this.isXs ? 'api' : 'web';
    this.apilink +=
      "api.whatsapp.com/send?phone=+573204749856&text=" +
      encodeURI("Deseo más información ...");
  },
  methods: {
    resetCart() {
      this.cart = [];
    },
    changeData(e) {
      this.catetory = e;
      this.smart_lst_selected = this.menu[e];
    },
    findProduct(e) {
      if (e.keyCode === 13 && this.tosearch.length > 3) {
        var selected = [];
        if (this.tosearch) {
          selected = this.smart_lst.filter((o) => {
            if (o.product_name.toLowerCase().includes(this.tosearch.toLowerCase())) {
              return true;
            }
          });
          if (selected.length > 0) {
            this.smart_lst_selected = selected;
            this.catetory = "Busqueda";
          }
        } else {
          this.smart_lst_selected = this.smart_lst;
        }
      }
    },
    onResize() {
      this.isXs = window.innerWidth < 500;
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    openLink(e) {
      this.$router.push("/" + e + "/");
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => a + (b[key] || 0), 0);
    },
    get_products() {
      console.log("get_products", this.$store.getters.get_products_lst);

      if (this.$store.getters.get_products_lst.length > 0) {
        var data = this.$store.getters.get_products_lst;
        var resp = data.filter(function (item) {
          return item.main === "1";
        });

        this.main_lst = resp;

        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.menu = ctgry;
        this.smart_categories = Object.keys(ctgry).sort();
        this.smart_lst_selected = data;
        this.smart_lst = data;
      }
    },
    getbgpic(ctgry) {
      var phtslst = [];
      if (ctgry) {
        phtslst = this.menu[ctgry];
      } else {
        phtslst = this.menu;
      }
      if (phtslst.length > 0) {
        // phtslst = phtslst.filter(function (el) {
        //   return el.picture !== "";
        // });

        phtslst = phtslst.filter(function (item) {
          if (item["picture"] !== "" && item["main"] === "1") {
            return true;
          } else {
            return false;
          }
        });
      }

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];
        //console.log(rdm);

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getImagePhoto: function (account, store, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      // console.log(url);
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    updateTotals() {
      this.sc.qty = 0;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = 0;
      this.sc.total = 0;
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.pack = parseFloat(itm.pack);
          itm.cost = parseFloat(itm.cost);
          itm.price = parseFloat(itm.price);
          itm.discount = parseFloat(itm.discount);

          // var tax_value = parseFloat(itm.tax) / 100 + 1;
          itm.discount_value = (
            (parseFloat(itm.price) * parseFloat(itm.discount)) /
            100
          ).toFixed(2);

          itm.price_value = (
            parseFloat(itm.price) - parseFloat(itm.discount_value)
          ).toFixed(2);

          // itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(
          //   2
          // );

          // itm.tax_value = Math.round(
          //   (parseFloat(itm.price_amount) *
          //     parseFloat(itm.qty) *
          //     parseFloat(itm.pack) *
          //     itm.tax) /
          //     100
          // ).toFixed(2);

          this.sc.qty += parseFloat(itm.qty);
          this.sc.total += parseFloat(itm.price);
        });
        // this.updateTotals();
      } else {
        this.cart = [];
      }
      // var itms = this.cart;
      // var qty = 0;
      // var tax = 0;
      // var total = 0;

      // this.sc.qty = qty;
      // this.sc.subtotal = 0;
      // this.sc.discount = 0;
      // this.sc.tax = tax;
    },
    addItem(e) {
      //console.log(e);
      this.addItm = true;
      this.itemSelected = { ...e };
      this.$router.push({
        name: "Product",
        params: { item: { ...e }, addItm: this.addItm },
      });

      // this.Scart = false;
      // this.add_dialog = true;
    },
    editItem(e, idx) {
      // console.log(e, idx);
      this.addItm = false;
      this.options_selected = null;
      this.optSelected = [];
      if (e.options_selected) {
        e.options_selected = JSON.parse(e.options_selected);
      }

      //this.itemSelected = e;
      // this.itemSelected = { ...e };

      this.$router.push({ name: "Product", params: { item: { ...e } } });
      this.idx = idx;

      //this.Scart = false;
      //this.add_dialog = true;
    },
    // save_order() {
    //   //console.log(this.$store.getters.profile);
    //   this.get_oders();
    //   this.tab = "orders";
    // },
    // get_store() {
    //   var qry = {
    //     table: "stores",
    //     filters: [{ field: "code", operator: "=", value: this.company.code }],
    //   };
    //   webserver("get_table", qry, (data) => {
    //     //console.log(data);
    //     this.$store.dispatch("setCompany", data[0]);
    //     this.get_bestprice();
    //   });
    // },
    // get_oders() {
    //   var qry = {
    //     table: "docs",
    //     filters: [
    //       { field: "doc_type", operator: "=", value: "OR" },
    //       { field: "store", operator: "=", value: "mayorista.app" },
    //       {
    //         field: "userId",
    //         operator: "=",
    //         value: this.$store.getters.profile.code,
    //       },
    //     ],
    //   };
    //   webserver("get_table_clients", qry, (data) => {
    //     //console.log(data);
    //     this.orders = data;
    //   });
    // },
    cartqty() {
      //console.log("cartqty", localStorage.cart ? localStorage.cart : "no cart");
      if (localStorage.cart) {
        var itms = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        itms.forEach((itm) => {
          this.cart += parseFloat(itm.qty);
        });
      }
    },
  },
  watch: {
    "$store.getters.get_products_lst"(newVal) {
      if (newVal) {
        this.get_products();
      }
    },
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.client = this.$store.getters.profile;
      }
    },
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="css" scope>
.topleft {
  top: 10px !important;
  right: 10px !important;
  z-index: 1;
}

.wtsppbtn {
  bottom: 70px !important;
}

.cartbtn {
  top: 50px !important;
}

.toptop {
  padding-top: 64px !important;
}

.btom {
  bottom: 0px !important;
}

.btnprice {
  bottom: 20px !important;
  right: 5px !important;
}

.price {
  font-size: 0.8rem;
}

.navbar {
  overflow: hidden;
  position: fixed;
  /* Set the navbar to fixed position */
  top: 56;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 2;
}

.v-slide-group__content {
  justify-content: center;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.tshadow {
  text-shadow: black -1px 2px 6px;
  color: black;
}

.categories {
  border: 0.5px solid rgb(101, 101, 104);
}
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Clients/Welcome.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: Welcome,
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Contact',
        name: 'Contact',
        props: true,
        component: () =>
            import('../views/Clients/Contact.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Cart',
        name: 'Cart',
        props: true,
        component: () =>
            import('../views/Clients/Cart.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Product',
        name: 'Product',
        props: true,
        component: () =>
            import('../views/Clients/Product.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Android',
        name: 'Android',
        props: true,
        component: () =>
            import('../views/setting/android.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Profile',
        name: 'Profile',
        component: () =>
            import('../views/Clients/Profile.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    // {
    //     path: '/Cart',
    //     name: 'Cart',
    //     component: () =>
    //         import('../views/Clients/Cart.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         is_admin: true
    //     }
    // },
    {
        path: '/MyOrders',
        name: 'MyOrders',
        component: () =>
            import('../views/Clients/Orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Checkout',
        name: 'Checkout',
        component: () =>
            import('../views/Clients/Checkout.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
        {
        path: '/Registration',
        name: 'Registration',
        component: () =>
            import('../views/Clients/Registration.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.profile) {
            if (store.state.profile.code) {
                next()
            } else {
                next({
                    name: '/',
                    params: { nextUrl: to.fullPath }
                })
            }
            return
        } else {
            next({
                name: '/',
                params: { nextUrl: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router